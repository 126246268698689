class PlanDesktopAnimations {
  $section = $(".Genplan._panorama");

  constructor() {
    this.animation();
  }

  animation() {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: this.$section,
        start: "top bottom-=15%",
        end: "bottom center",
        scrub: 1,
      },
    });
    tl.from(this.$section.find(".Genplan__subtitle"), {
      opacity: 0,
      y: 50,
    });
    tl.from(
      this.$section.find(".Genplan__title"),
      {
        x: -100,
      },
      "<"
    );
    tl.from(
      this.$section.find(".Genplan__text"),
      {
        opacity: 0,
        y: 50,
      },
      "+=0.5"
    );
  }
}
