class PlaceDesktopAnimations {
  $container = $('.Place');

  constructor() {
    this.initTextsAnimation();
    this.initMapAnimation();
  }

  initTextsAnimation() {
    const $titleParts = $('.Place__title-part');

    const scrollTrigger = {
      trigger: this.$container[0],
      scrub: 1,
      start: 'top bottom',
      end: '+=100%',
    };

    gsap.from($titleParts.eq(0), {
      x: '-10%',
      scrollTrigger,
    });

    gsap.from($titleParts.eq(1), {
      x: '-15%',
      scrollTrigger,
    });

    gsap.from('.Place__description', {
      y: '65%',
      scrollTrigger,
    });
  }

  initMapAnimation() {
    gsap.from('.Place__map', {
      x: '5%',
      scrollTrigger: {
        trigger: this.$container[0],
        scrub: 1,
        start: 'top bottom',
        end: '50% bottom',
      },
    });
  }
}
