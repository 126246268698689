(function () {
  new Swiper('#garden-slider', {
    speed: 700,
    loop: true,
    slidesPerView: 1,
    spaceBetween: 0,
    centeredSlides: true,
    navigation: {
      nextEl: '.js-garden-button-next',
      prevEl: '.js-garden-button-prev',
    },
  });

  if (ANIMATIONS_ENABLED) {
    new GardenDesktopAnimations();
  }
})();
