(function () {
  new Swiper('#architecture-slider', {
    speed: 700,
    loop: true,
    slidesPerView: 1,
    navigation: {
      nextEl: '.js-architecture-button-next',
      prevEl: '.js-architecture-button-prev',
    },
  });

  if (ANIMATIONS_ENABLED) {
    new ArchitectureDesktopAnimations();
  }
})();
