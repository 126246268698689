(function() {
  const initScrollLogic = () => {
    const $sections = $('.js-page-section:visible').add('.Footer');

    const onScroll = () => {
      const pageTop = window.pageYOffset;
      const pageBot = pageTop + window.innerHeight;
      const $scrolledSections = $sections.filter(function() {
        const sectionTop = this.getBoundingClientRect().top + pageTop;
        if (sectionTop <= pageTop && sectionTop <= pageBot) {
          return true;
        } else {
          return false;
        }
      });
      const $currentLastSection = $scrolledSections.last();
      const headerColor = $currentLastSection.attr('data-header-color');
      if (!$currentLastSection.hasClass('Location-wrap')) {
        $('.Header').removeClass('_above-time-panel');
      }
      $('.Header').attr('data-color', headerColor);
      if (pageTop < 10) {
        $('.Header').addClass('_page-at-top');
      } else {
        $('.Header').removeClass('_page-at-top');
      }
    };

    window.addEventListener('scroll', onScroll);
    onScroll();
  };

  const animateIntro = () => {
    $('body').addClass('_noScroll');
    $('.Header').addClass('_animated');

    setTimeout(() => {
      const $header = $('header');
      const $el = $(window.location.hash);
      if (window.location.hash) {
        $('html, body').animate({
          scrollTop: $el.offset().top - (window.matchMedia('(max-width: 1279px)').matches ? $header.height() / 2 : 0),
        }, 10);
      }
    }, 100)

    setTimeout(() => {
      $('body').removeClass('_noScroll');
      const $preloader = $(".Preloader");
      $preloader.removeClass('Preloader-active');
    }, 1500);
  };

  initScrollLogic();
  animateIntro();
  // $('body').removeClass('_noScroll');
})();
