class ArchitectureDesktopAnimations {
  $wrap = $(".Architecture");

  constructor() {
    this.init();
  }

  init() {
    this.initTextsAnimation();
  }

  initTextsAnimation() {
    const t1 = gsap.timeline({
      scrollTrigger: {
        trigger: this.$wrap,
        scrub: 1,
        start: "top bottom",
        end: "top top",
      },
    });

    t1.from(".Architecture__title", {
      x: "-10%",
    });

    t1.from(
      ".Architecture__quote",
      {
        y: 140,
        opacity: 0,
      },
      "<40%"
    );
  }
}
