gsap.registerPlugin(ScrollTrigger);
const IS_TOUCH_DEVICE = window.matchMedia("(pointer: coarse)").matches;
const ANIMATIONS_ENABLED = !IS_TOUCH_DEVICE && window.innerWidth >= 1280;

$(document).ready(function() {
  window.scrollTo(0, 0);

  (function() {
    const initScrollLogic = () => {
      const $sections = $('.js-page-section:visible').add('.Footer');
  
      const onScroll = () => {
        const pageTop = window.pageYOffset;
        const pageBot = pageTop + window.innerHeight;
        const $scrolledSections = $sections.filter(function() {
          const sectionTop = this.getBoundingClientRect().top + pageTop;
          if (sectionTop <= pageTop && sectionTop <= pageBot) {
            return true;
          } else {
            return false;
          }
        });
        const $currentLastSection = $scrolledSections.last();
        const headerColor = $currentLastSection.attr('data-header-color');
        if (!$currentLastSection.hasClass('Location-wrap')) {
          $('.Header').removeClass('_above-time-panel');
        }
        $('.Header').attr('data-color', headerColor);
        if (pageTop < 10) {
          $('.Header').addClass('_page-at-top');
        } else {
          $('.Header').removeClass('_page-at-top');
        }
      };
  
      window.addEventListener('scroll', onScroll);
      onScroll();
    };
  
    const animateIntro = () => {
      $('body').addClass('_noScroll');
      $('.Header').addClass('_animated');
  
      setTimeout(() => {
        const $header = $('header');
        const $el = $(window.location.hash);
        if (window.location.hash) {
          $('html, body').animate({
            scrollTop: $el.offset().top - (window.matchMedia('(max-width: 1279px)').matches ? $header.height() / 2 : 0),
          }, 10);
        }
      }, 100)
  
      setTimeout(() => {
        $('body').removeClass('_noScroll');
        const $preloader = $(".Preloader");
        $preloader.removeClass('Preloader-active');
      }, 1500);
    };
  
    initScrollLogic();
    animateIntro();
    // $('body').removeClass('_noScroll');
  })();
  
  (function () {
    if (IS_TOUCH_DEVICE || window.innerWidth < 1280) return;
  
    const $container = $('.Top');
    // const $image = $container.find('.Top__bg');
    const $logo = $container.find('.Top__logo');
    const $title = $container.find('.Top__title');
    const $text = $container.find('.Top__text');
  
    const $advantages = $('.Advantages');
    const $advContent = $advantages.find('.Advantages__content');
    const $advGallery = $advantages.find('.Advantages__gallery');
    const $slides = $advGallery.find('.Advantage-card');
  
    gsap.set($advGallery, {
      opacity: 1,
      rotate: 60,
      left: '50%',
      x: -225,
    });
  
    const mainTl = gsap.timeline({
      scrollTrigger: {
        trigger: $container[0],
        scrub: 0.7,
        start: 0,
        end: '+=6000',
        pin: true,
        anticipatePin: true,
        snap: {
          snapTo: 'labelsDirectional',
          ease: 'none',
          duration: { min: 0.1, max: 0.5 },
          delay: 0.2,
          inertia: false,
        },
        onLeave() {
          $advContent.css({
            position: 'absolute',
          });
        },
        onEnterBack() {
          $advContent.css({
            position: 'fixed',
          });
        },
      },
    });
  
    mainTl.to($logo, {
      y: () => -$logo[0].offsetTop + 57,
      width: window.innerHeight < 850 ? 368 : 620,
    });
  
    mainTl
      .to(
        [$text, $title],
        {
          opacity: 0,
          y: '100%',
        },
        '<'
      )
      .addLabel('start', 0);
  
    $slides.each((idx) => {
      mainTl
        .to(
          $advGallery,
          {
            rotate: idx * -15,
            ease: 'none',
          },
          !idx ? '-=0.4' : '>'
        )
        .addLabel(`card_${idx}`);
    });
  })();
  
  class PlaceDesktopAnimations {
    $container = $('.Place');
  
    constructor() {
      this.initTextsAnimation();
      this.initMapAnimation();
    }
  
    initTextsAnimation() {
      const $titleParts = $('.Place__title-part');
  
      const scrollTrigger = {
        trigger: this.$container[0],
        scrub: 1,
        start: 'top bottom',
        end: '+=100%',
      };
  
      gsap.from($titleParts.eq(0), {
        x: '-10%',
        scrollTrigger,
      });
  
      gsap.from($titleParts.eq(1), {
        x: '-15%',
        scrollTrigger,
      });
  
      gsap.from('.Place__description', {
        y: '65%',
        scrollTrigger,
      });
    }
  
    initMapAnimation() {
      gsap.from('.Place__map', {
        x: '5%',
        scrollTrigger: {
          trigger: this.$container[0],
          scrub: 1,
          start: 'top bottom',
          end: '50% bottom',
        },
      });
    }
  }
  
    (function () {
    if (ANIMATIONS_ENABLED) {
      new PlaceDesktopAnimations();
    }
  
    new PlacesMap($('#PlaceMapDesktop'));
    new PlacesMap($('#PlaceMapMobile'));
  })();
  
  class ArchitectureDesktopAnimations {
    $wrap = $(".Architecture");
  
    constructor() {
      this.init();
    }
  
    init() {
      this.initTextsAnimation();
    }
  
    initTextsAnimation() {
      const t1 = gsap.timeline({
        scrollTrigger: {
          trigger: this.$wrap,
          scrub: 1,
          start: "top bottom",
          end: "top top",
        },
      });
  
      t1.from(".Architecture__title", {
        x: "-10%",
      });
  
      t1.from(
        ".Architecture__quote",
        {
          y: 140,
          opacity: 0,
        },
        "<40%"
      );
    }
  }
  
    (function () {
    new Swiper('#architecture-slider', {
      speed: 700,
      loop: true,
      slidesPerView: 1,
      navigation: {
        nextEl: '.js-architecture-button-next',
        prevEl: '.js-architecture-button-prev',
      },
    });
  
    if (ANIMATIONS_ENABLED) {
      new ArchitectureDesktopAnimations();
    }
  })();
  
  class PlanDesktopAnimations {
    $section = $(".Genplan._panorama");
  
    constructor() {
      this.animation();
    }
  
    animation() {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: this.$section,
          start: "top bottom-=15%",
          end: "bottom center",
          scrub: 1,
        },
      });
      tl.from(this.$section.find(".Genplan__subtitle"), {
        opacity: 0,
        y: 50,
      });
      tl.from(
        this.$section.find(".Genplan__title"),
        {
          x: -100,
        },
        "<"
      );
      tl.from(
        this.$section.find(".Genplan__text"),
        {
          opacity: 0,
          y: 50,
        },
        "+=0.5"
      );
    }
  }
  
    (function () {
    if (ANIMATIONS_ENABLED) {
      new PlanDesktopAnimations();
    }
  
    new PlacesMap($('.Genplan._panorama'));
  })();
  
  class PortalDesktopAnimations {
    $container = $(".Portal");
    $title = $(".Portal__title-part");
    $text = $(".Portal__text");
    $subtext = $(".Portal__subtext");
    $imgBig = $(".Portal__img._big");
    $imgSmall = $(".Portal__img._small");
  
    constructor() {
      this.init();
    }
  
    init() {
      if (!this.$container.length) return;
      this.initTextsAnimation();
      this.initImagesAnimation();
    }
  
    initTextsAnimation() {
      const isWide = (a, b) => (window.innerWidth >= 1600 ? a : b ?? a);
      const isShort = (a, b) => (window.innerHeight <= 800 ? a : b ?? a);
      const titleTrigger = {
        trigger: this.$title,
        scrub: 1,
        start: "top bottom",
        end: "bottom top",
      };
      const textTrigger = {
        scrub: true,
        start: "top bottom",
        end: "top center",
      };
      gsap.to(this.$title.eq(0), {
        x: isWide("10%"),
        scrollTrigger: titleTrigger,
      });
      gsap.to(this.$title.eq(1), {
        x: isWide("-15%", "15%"),
        scrollTrigger: titleTrigger,
      });
      gsap.to(this.$title.eq(2), {
        x: isWide("15%", "-15%"),
        scrollTrigger: titleTrigger,
      });
      gsap.fromTo(
        this.$text[0],
        {
          y: isShort("15%", "30%"),
          opacity: 0,
        },
        {
          y: 0,
          opacity: 1,
          scrollTrigger: {
            ...textTrigger,
            trigger: this.$text[0],
          },
        }
      );
      gsap.fromTo(
        this.$subtext[0],
        {
          y: isShort("15%", "30%"),
          opacity: 0,
        },
        {
          y: 0,
          opacity: 1,
          scrollTrigger: {
            ...textTrigger,
            trigger: this.$subtext[0],
          },
        }
      );
    }
  
    initImagesAnimation() {
      gsap.fromTo(
        this.$imgSmall[0],
        {
          y: "30%",
          opacity: 0,
        },
        {
          y: 0,
          opacity: 1,
          scrollTrigger: {
            trigger: this.$imgSmall[0],
            scrub: true,
            start: "top bottom+=100",
            end: "top",
          },
        }
      );
      gsap.fromTo(
        this.$imgBig[0],
        {
          y: "10%",
          opacity: 0,
        },
        {
          y: 0,
          opacity: 1,
          scrollTrigger: {
            trigger: this.$imgBig[0],
            scrub: true,
            start: "top bottom-=100",
            end: "top center-=100",
          },
        }
      );
    }
  }
  
    (function () {
    if (ANIMATIONS_ENABLED) {
      new PortalDesktopAnimations();
    }
  })();
  
  class ResidenceDesktopAnimations {
    $outerWrap = $('.Residence');
    $wrap = $('.Residence__scroller');
    $sections = $('.Residence__scroller > section');
    totalTransform = this.$wrap[0].scrollWidth - this.$wrap.outerWidth(true);
    sectionsWidth = Array.from(this.$sections.not('._mobile')).map((e) =>
      $(e).outerWidth(true)
    );
    $images = $('[data-residence-parallax]');
    $bg = this.$outerWrap.find('.Residence__bg');
    constructor() {
      this.init();
    }
  
    init() {
      this.initWrapAnimation();
      this.initImagesAnimations();
      this.initTitlesAnimations();
    }
  
    initWrapAnimation() {
      gsap.to(this.$wrap[0], {
        x: -this.totalTransform,
        ease: 'none',
        scrollTrigger: {
          pin: true,
          scrub: true,
          trigger: this.$wrap[0],
          start: 'top top',
          end: `+=${this.totalTransform}px`,
          onUpdate: () => {
            const src = ScrollTrigger.positionInViewport('.Residence__freedom', 'top', true) < 0.6 ? 'url(../img/residence-2.png)':'url(../img/residence-3.jpg)';
            this.$bg.css('background-image', src);
          }
        },
      });
  
      gsap.to(this.$wrap[0], {
        scale: 1,
        ease: 'none',
        scrollTrigger: {
          pin: true,
          pinSpacing: false,
          trigger: this.$outerWrap[0],
          start: `bottom+=1px bottom`,
          end: `+=${window.innerHeight}px`,
          // toggleActions: 'play none reverse none',
          onUpdate: (self) => console.log('progress:', self.progress),
        },
      });
    }
  
    initImagesAnimations() {
      this.$images.each((_, e) => {
        const $currentSection = $(e).closest('section');
        const sectionIdx = this.$sections.index($currentSection);
        const wrapImg = $(e).parent()[0];
  
        gsap.to(e, {
          x: '30%',
          scrollTrigger: {
            scrub: 0,
            trigger: this.$outerWrap[0],
            start: `+=${
              sectionIdx === 0
                ? 0
                : wrapImg.getBoundingClientRect().x - window.innerWidth
            }px`,
            end: () => {
              if (sectionIdx === this.$sections.length - 1) {
                return `+=${
                  this.$wrap[0].scrollWidth - wrapImg.getBoundingClientRect().x
                }`;
              } else {
                return `+=${window.innerWidth * 1.5}`;
              }
            },
          },
        });
      });
    }
  
    initTitlesAnimations() {
      gsap.from('.Residence__freedom .Residence__title-main span:nth-child(1)', {
        x: '-10%',
        ease: 'none',
        scrollTrigger: {
          trigger: this.$wrap[0],
          scrub: 1,
          start: 'top center',
          end: 'top top',
        },
      });
  
      gsap.from('.Residence__freedom .Residence__title-main span:nth-child(2)', {
        x: '-5%',
        ease: 'none',
        scrollTrigger: {
          trigger: this.$wrap[0],
          scrub: 1,
          start: 'top center',
          end: 'top top',
        },
      });
  
      gsap.fromTo(
        '.Residence__privacy .Residence__title-main span:nth-child(1)',
        { x: '0%' },
        {
          x: '30%',
          ease: 'none',
          scrollTrigger: {
            trigger: this.$wrap[0],
            scrub: 1,
            start: 'top top',
            end: `=+${$('.Residence__privacy').outerWidth(true)}`,
          },
        }
      );
  
      gsap.fromTo(
        '.Residence__privacy .Residence__title-main span:nth-child(2)',
        { x: '0%' },
        {
          x: '10%',
          ease: 'none',
          scrollTrigger: {
            trigger: this.$wrap[0],
            scrub: 1,
            start: 'top top',
            end: `=+${$('.Residence__privacy').outerWidth(true)}`,
          },
        }
      );
  
      const $residenceTitleRow2 = $('.Residence__relax-title-row._2');
      gsap.to($residenceTitleRow2, {
        x: '-15%',
        ease: 'none',
        scrollTrigger: {
          trigger: this.$outerWrap[0],
          scrub: 1,
          start: `+=${
            $residenceTitleRow2[0].getBoundingClientRect().x - window.innerWidth
          }`,
          end: `+=${$residenceTitleRow2.outerWidth(true) + window.innerWidth}`,
        },
      });
  
      const $residenceTitleRow3 = $('.Residence__relax-title-row._3');
      gsap.to($residenceTitleRow3, {
        x: '-40%',
        ease: 'none',
        scrollTrigger: {
          trigger: this.$outerWrap[0],
          scrub: 1,
          start: `+=${
            $residenceTitleRow3[0].getBoundingClientRect().x - window.innerWidth
          }`,
          end: `+=${$residenceTitleRow3.outerWidth(true) + window.innerWidth}`,
        },
      });
    }
  }
  
    (function () {
    // if (ANIMATIONS_ENABLED) {
    //   new ResidenceDesktopAnimations();
    // } else {
    //   gsap.to($('.Residence__bg')[0], {
    //     scrollTrigger: {
    //       trigger: $('.Residence__freedom'),
    //       start: 'top 80%',
    //       end: '200% top',
    //       onToggle: () => {
    //         const src = ScrollTrigger.positionInViewport('.Residence__freedom', 'top') < 0.8 ? 'url(../img/residence-2.png)':'url(../img/residence-3.jpg)';
    //         $('.Residence__bg').css('background-image', src);
    //       }
    //     },
    //   });
    // }
  })();
  
  class GardenDesktopAnimations {
    $container = $(".Garden");
  
    constructor() {
      this.init();
    }
  
    init() {
      this.initTextsAnimation();
    }
  
    initTextsAnimation() {
      const $titleParts = $(".Garden__title-part");
  
      const scrollTrigger = {
        trigger: this.$container[0],
        scrub: 1,
        start: 'top bottom',
        end: '50% bottom',
      };
  
      gsap.from([$titleParts.eq(0), $titleParts.eq(2)], {
        x: "10%",
        scrollTrigger,
      });
  
      gsap.from([$titleParts.eq(1)], {
        x: "-10%",
        scrollTrigger,
      });
  
      gsap.fromTo(
        $(".Garden__description")[0],
        {
          y: "30%",
          opacity: 0,
        },
        {
          y: 0,
          opacity: 1,
          scrollTrigger: {
            trigger: $(".Garden__description")[0],
            scrub: 1,
            start: "top bottom",
            end: "top center",
          },
        }
      );
    }
  }
  
    (function () {
    new Swiper('#garden-slider', {
      speed: 700,
      loop: true,
      slidesPerView: 1,
      spaceBetween: 0,
      centeredSlides: true,
      navigation: {
        nextEl: '.js-garden-button-next',
        prevEl: '.js-garden-button-prev',
      },
    });
  
    if (ANIMATIONS_ENABLED) {
      new GardenDesktopAnimations();
    }
  })();
  
  class AdvantagesDesktopAnimations {
    constructor() { }
  }
  
    (function() {
    const swiper = new Swiper("#advantages-slider", {
      init: false,
      speed: 700,
      watchOverflow: true,
      observeParents: true,
      observer: true,
      slidesPerView: "auto",
      breakpoints: {
        0: {
          init: true,
          slidesPerView: 1,
          spaceBetween: 16,
        },
        639: {
          init: true,
          slidesPerView: "auto",
          spaceBetween: 16,
        },
      },
      navigation: {
        nextEl: ".Advantages__navigation._next",
        prevEl: ".Advantages__navigation._prev",
      },
    });
    if (ANIMATIONS_ENABLED) {
      new AdvantagesDesktopAnimations();
    } else {
      swiper.init();
    }
  })();
  

  // setTimeout(() => {
  //   document.querySelector('.Garden').scrollIntoView(true);
  // }, 500);


  const refreshFunc = debounce(function() {
    ScrollTrigger.update();
  });

  $(window).on("resize orientationchange", refreshFunc);
  $(".Footer__up").on("click", () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  });

  setTimeout(() => {
    if (IS_TOUCH_DEVICE) return;
    $("[data-parallax]").each(function() {
      const orientation = $(this).data("parallax") || "up";
      new simpleParallax(this, {
        delay: 2,
        scale: 1.2,
        orientation,
      });
    });
  }, 500);
});
