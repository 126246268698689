class PortalDesktopAnimations {
  $container = $(".Portal");
  $title = $(".Portal__title-part");
  $text = $(".Portal__text");
  $subtext = $(".Portal__subtext");
  $imgBig = $(".Portal__img._big");
  $imgSmall = $(".Portal__img._small");

  constructor() {
    this.init();
  }

  init() {
    if (!this.$container.length) return;
    this.initTextsAnimation();
    this.initImagesAnimation();
  }

  initTextsAnimation() {
    const isWide = (a, b) => (window.innerWidth >= 1600 ? a : b ?? a);
    const isShort = (a, b) => (window.innerHeight <= 800 ? a : b ?? a);
    const titleTrigger = {
      trigger: this.$title,
      scrub: 1,
      start: "top bottom",
      end: "bottom top",
    };
    const textTrigger = {
      scrub: true,
      start: "top bottom",
      end: "top center",
    };
    gsap.to(this.$title.eq(0), {
      x: isWide("10%"),
      scrollTrigger: titleTrigger,
    });
    gsap.to(this.$title.eq(1), {
      x: isWide("-15%", "15%"),
      scrollTrigger: titleTrigger,
    });
    gsap.to(this.$title.eq(2), {
      x: isWide("15%", "-15%"),
      scrollTrigger: titleTrigger,
    });
    gsap.fromTo(
      this.$text[0],
      {
        y: isShort("15%", "30%"),
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        scrollTrigger: {
          ...textTrigger,
          trigger: this.$text[0],
        },
      }
    );
    gsap.fromTo(
      this.$subtext[0],
      {
        y: isShort("15%", "30%"),
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        scrollTrigger: {
          ...textTrigger,
          trigger: this.$subtext[0],
        },
      }
    );
  }

  initImagesAnimation() {
    gsap.fromTo(
      this.$imgSmall[0],
      {
        y: "30%",
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: this.$imgSmall[0],
          scrub: true,
          start: "top bottom+=100",
          end: "top",
        },
      }
    );
    gsap.fromTo(
      this.$imgBig[0],
      {
        y: "10%",
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: this.$imgBig[0],
          scrub: true,
          start: "top bottom-=100",
          end: "top center-=100",
        },
      }
    );
  }
}
