(function () {
  if (IS_TOUCH_DEVICE || window.innerWidth < 1280) return;

  const $container = $('.Top');
  // const $image = $container.find('.Top__bg');
  const $logo = $container.find('.Top__logo');
  const $title = $container.find('.Top__title');
  const $text = $container.find('.Top__text');

  const $advantages = $('.Advantages');
  const $advContent = $advantages.find('.Advantages__content');
  const $advGallery = $advantages.find('.Advantages__gallery');
  const $slides = $advGallery.find('.Advantage-card');

  gsap.set($advGallery, {
    opacity: 1,
    rotate: 60,
    left: '50%',
    x: -225,
  });

  const mainTl = gsap.timeline({
    scrollTrigger: {
      trigger: $container[0],
      scrub: 0.7,
      start: 0,
      end: '+=6000',
      pin: true,
      anticipatePin: true,
      snap: {
        snapTo: 'labelsDirectional',
        ease: 'none',
        duration: { min: 0.1, max: 0.5 },
        delay: 0.2,
        inertia: false,
      },
      onLeave() {
        $advContent.css({
          position: 'absolute',
        });
      },
      onEnterBack() {
        $advContent.css({
          position: 'fixed',
        });
      },
    },
  });

  mainTl.to($logo, {
    y: () => -$logo[0].offsetTop + 57,
    width: window.innerHeight < 850 ? 368 : 620,
  });

  mainTl
    .to(
      [$text, $title],
      {
        opacity: 0,
        y: '100%',
      },
      '<'
    )
    .addLabel('start', 0);

  $slides.each((idx) => {
    mainTl
      .to(
        $advGallery,
        {
          rotate: idx * -15,
          ease: 'none',
        },
        !idx ? '-=0.4' : '>'
      )
      .addLabel(`card_${idx}`);
  });
})();
