(function() {
  const swiper = new Swiper("#advantages-slider", {
    init: false,
    speed: 700,
    watchOverflow: true,
    observeParents: true,
    observer: true,
    slidesPerView: "auto",
    breakpoints: {
      0: {
        init: true,
        slidesPerView: 1,
        spaceBetween: 16,
      },
      639: {
        init: true,
        slidesPerView: "auto",
        spaceBetween: 16,
      },
    },
    navigation: {
      nextEl: ".Advantages__navigation._next",
      prevEl: ".Advantages__navigation._prev",
    },
  });
  if (ANIMATIONS_ENABLED) {
    new AdvantagesDesktopAnimations();
  } else {
    swiper.init();
  }
})();
