class GardenDesktopAnimations {
  $container = $(".Garden");

  constructor() {
    this.init();
  }

  init() {
    this.initTextsAnimation();
  }

  initTextsAnimation() {
    const $titleParts = $(".Garden__title-part");

    const scrollTrigger = {
      trigger: this.$container[0],
      scrub: 1,
      start: 'top bottom',
      end: '50% bottom',
    };

    gsap.from([$titleParts.eq(0), $titleParts.eq(2)], {
      x: "10%",
      scrollTrigger,
    });

    gsap.from([$titleParts.eq(1)], {
      x: "-10%",
      scrollTrigger,
    });

    gsap.fromTo(
      $(".Garden__description")[0],
      {
        y: "30%",
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: $(".Garden__description")[0],
          scrub: 1,
          start: "top bottom",
          end: "top center",
        },
      }
    );
  }
}
